import { useRollbar } from '@rollbar/react'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import VideoUi from 'common/components/entities/Video/ui/VideoUi'
import { BorderType } from 'common/types/styleTypes'
import UnMute from './ui/UnMuteUi'

type BasePlayerProps = {
  attrId: string
  url: string
  autoplay: boolean
  controls?: boolean
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
  onReady?: (video: ReactPlayer) => void
  posterUrl?: string
}

function BasePlayer({
  attrId,
  url,
  border,
  mobileBorder,
  controls = false,
  autoplay = false,
  boxShadow,
  mobileBoxShadow,
  onReady,
  posterUrl,
}: BasePlayerProps) {
  const rollbar = useRollbar()
  const [muted, setMuted] = useState(false)
  const [hasWindow, setHasWindow] = useState(false)
  const [volume, setVolume] = useState(1)
  const video = useRef<ReactPlayer>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  async function playerPlay(volume: number) {
    try {
      if (video.current) {
        const player = video.current.getInternalPlayer()
        player.volume = volume
        if (player.play) {
          await player.play()
        } else if (player.playVideo) {
          await player.playVideo()
        } else {
          // disabled due to high amount of uninformative reports
          // rollbar.error(
          //   "Couldn't play video because the play method was not found.",
          //   { url },
          // )
        }
      }
    } catch (e) {
      // disabled due to high amount of uninformative reports
      // rollbar.error("Couldn't play the video.", { url })
    }
  }

  async function playerPlayMuted() {
    setMuted(true)
    await playerPlay(0)
  }

  async function playerPlayWithSound() {
    setMuted(false)
    await playerPlay(1)
  }

  const onError = async (e: any) => {
    if (e.name === 'NotAllowedError') {
      await playerPlayMuted()
    }
  }

  // fixes autoplay issue on lecture pages
  useEffect(() => {
    if (autoplay) {
      playerPlayMuted()
    }
  }, [autoplay])

  if (!url) {
    return (
      <VideoUi
        id={attrId}
        border={border}
        mobileBorder={mobileBorder}
        disabledPointerEvents={false}
      />
    )
  }

  return (
    <VideoUi
      id={attrId}
      border={border}
      mobileBorder={mobileBorder}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      disabledPointerEvents={false}
    >
      {muted && <UnMute onClick={playerPlayWithSound} />}
      {hasWindow && (
        <ReactPlayer
          ref={video}
          width="100%"
          height="100%"
          url={url}
          className="react-player"
          playing={autoplay}
          onError={onError}
          onReady={onReady}
          onStart={() => setVolume(1)} // need for YouTube, Vimeo plays with sound
          muted={muted}
          controls={controls}
          playsinline={true}
          volume={volume}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
                poster: posterUrl,
              },
            },
            youtube: {
              // Called when state changes to unstarted (usually when video fails to autoplay)
              onUnstarted: playerPlayMuted,
            },
          }}
        />
      )}
    </VideoUi>
  )
}

export default BasePlayer
