import { getPageTypeEntities } from 'common/constants/pageTypeEntitiesMap'
import structureTypes from 'common/constants/structureTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import {
  EntityTypeEnum,
  getReadableRootEntityTypeByPageType,
} from 'common/enums/entityTypeEnum'
import { EntitiesStructure, EntityType } from 'common/types/Page'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import EntityInterface, {
  BaseEntityInterface,
  BaseEntityWithChildIdsInterface,
  EntityInnerItemInterface,
  EntityUnionType,
} from 'common/types/entities/EntityInterface'
import {
  OldEntityInterface,
  OldEntityWithChildIds,
} from 'common/types/entities/OldEntityInterface'
import { PageState } from 'publisher/store/page/PageStateInterface'
import { EntitiesType } from '../../server/types/ServerPageInterface'

type Entities = (
  | BaseEntityInterface
  | EntityInnerItemInterface
  | OldEntityInterface
  | CheckboxInterface
)[]

export function getAllDescendantsByEntityType(
  entities: EntitiesStructure,
  nodeId: string,
  entityType: EntityType,
): Entities {
  const entity = entities[nodeId]
  if (!entity || !entity.childIds) {
    return [] as Entities
  }

  return entity.childIds.reduce((prev, childId) => {
    //hack for lost entities
    if (!entities[childId]) {
      return prev
    }
    if (entities[childId].type === entityType) {
      return [...prev, entities[childId]]
    }

    return [
      ...prev,
      ...getAllDescendantsByEntityType(entities, childId, entityType),
    ]
  }, [] as Entities)
}

export function getEntitiesByType(
  entities: EntitiesStructure,
  type: EntityType,
): EntitiesStructure {
  return Object.values(entities)
    .filter(entity => entity.type === type)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    )
}

export function getEntityAncestors(
  entities: EntitiesStructure,
  targetEntity: EntityUnionType,
): EntitiesStructure {
  return Object.values(entities)
    .filter(entity => entity.id === targetEntity.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
        ...(cur.parentId && entities[cur.parentId]
          ? getEntityAncestors(entities, entities[cur.parentId])
          : null),
      }),
      {},
    )
}

export function getEntityParents(
  entities: EntitiesStructure,
  targetEntity: EntityUnionType,
): EntitiesStructure {
  return Object.values(entities)
    .filter(entity => entity.id === targetEntity.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
        ...(cur.parentId && entities[cur.parentId]
          ? getEntityParents(entities, entities[cur.parentId])
          : null),
      }),
      {},
    )
}

export function isEntityWithChildIds(
  entity: BaseEntityInterface | OldEntityInterface,
): entity is BaseEntityWithChildIdsInterface | OldEntityWithChildIds {
  return entity.childIds !== undefined
}

export function getEntityExistingChildIds(
  entities: EntitiesType,
  entity: BaseEntityWithChildIdsInterface | OldEntityWithChildIds,
) {
  return entity.childIds.filter(id => Boolean(entities[id]))
}

export const getParentEntityTreeWithFixedParentId = (
  entities: EntitiesType,
  parentEntity: BaseEntityWithChildIdsInterface | OldEntityWithChildIds,
): EntitiesType => {
  const result: EntitiesType = {}

  Object.values(entities).forEach(currentEntity => {
    if (parentEntity.childIds.includes(currentEntity.id)) {
      result[currentEntity.id] = {
        ...currentEntity,
        parentId: parentEntity.id,
      }

      if (isWithChildIds(currentEntity)) {
        Object.assign(
          result,
          getParentEntityTreeWithFixedParentId(entities, currentEntity),
        )
      }
    }
  })

  return result
}

export function isWithChildIds(
  entity: EntityUnionType,
): entity is BaseEntityWithChildIdsInterface | OldEntityWithChildIds {
  return entity.childIds !== undefined
}

export function getEntityAncestorByType(
  entities: EntitiesStructure,
  targetEntity: EntityUnionType,
  type: EntityTypeEnum | string,
) {
  const parents = getEntityParents(entities, targetEntity)
  return Object.values(parents).find(entity => entity.type === type)
}

export function findEntityByType(
  entities: EntitiesStructure,
  type: PageTypeEnum | string,
) {
  return Object.values(entities).find(entity => entity.type === type)
}

export function getOrderedByPositionHeadings(
  entities: EntitiesStructure,
  isMobile: boolean,
  pageType: PageTypeEnum,
) {
  const rootEntity = Object.values(entities).find(entity =>
    getReadableRootEntityTypeByPageType(pageType).includes(entity.type),
  ) as OldEntityWithChildIds

  return rootEntity.childIds.reduce((acc, childId) => {
    const headlines = getAllHeadingDescendantsByEntityType(
      entities,
      childId,
      EntityTypeEnum.Headline,
      isMobile,
    )

    return [...acc, ...headlines]
  }, [] as Entities)
}

export function isPopupAscendantOfEntity(
  popup: EntityUnionType,
  targetEntity: EntityUnionType,
  entities: EntitiesStructure,
) {
  return getAllDescendantIds(entities, popup.id).includes(targetEntity.id)
}

export function isVisible(entity: EntityUnionType, isDesktop: boolean) {
  if (entity.type in EntityTypeEnum) {
    const newEntity = entity as EntityInterface | EntityInnerItemInterface
    if (!('appearance' in newEntity)) {
      return true
    }

    if (isDesktop) {
      // desktop visibility
      return newEntity.appearance.desktop === isDesktop
    } else {
      // mobile visibility
      return newEntity.appearance.mobile === true
    }
  }

  const oldEntity = entity as OldEntityInterface
  if (!oldEntity.options.appearance) {
    return true
  }

  if (isDesktop) {
    // desktop visibility
    return oldEntity.options.appearance.desktop === isDesktop
  } else {
    // mobile visibility
    return oldEntity.options.appearance.mobile === true
  }
}

export function getVisibleBlogPostListings(
  state: PageState,
  isDesktop: boolean,
  popupId?: string | null,
) {
  return Object.values(state.entities)
    .filter(entity => entity.type === EntityTypeEnum.BlogPostListing)
    .filter(
      entity =>
        isVisible(entity, isDesktop) &&
        hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
    .filter(entity =>
      popupId
        ? isPopupAscendantOfEntity(
            state.entities[popupId],
            entity,
            state.entities,
          )
        : isNotBelongsToPopups(entity, state.entities),
    )
}

export const getAscendantPopup = (
  state: PageState,
  entity: EntityInterface,
) => {
  return getEntityAncestorByType(state.entities, entity, structureTypes.POPUP)
}

export function isNotBelongsToPopups(
  targetEntity: EntityUnionType,
  entities: EntitiesStructure,
) {
  const popups = getEntitiesByType(entities, structureTypes.POPUP)
  return Object.values(popups).every(
    popup =>
      getAllDescendantIds(entities, popup.id).includes(targetEntity.id) ===
      false,
  )
}

export function getAllDescendantIds(
  entities: EntitiesStructure,
  nodeId: string,
): string[] {
  const entity = entities[nodeId]
  if (!entity || !entity.childIds) {
    return []
  }

  return entity.childIds.reduce(
    (prev, childId) => [
      ...prev,
      childId,
      ...getAllDescendantIds(entities, childId),
    ],
    [] as string[],
  )
}

function getAllHeadingDescendantsByEntityType(
  entities: EntitiesStructure,
  nodeId: string,
  entityType: EntityType,
  isMobile: boolean,
): Entities {
  const entity = entities[nodeId]

  if (!entity || !entity.childIds || isVisible(entity, !isMobile) === false) {
    return [] as Entities
  }

  return entity.childIds.reduce((prev, childId) => {
    if (entities[childId] && entities[childId].type === entityType) {
      return [...prev, entities[childId]]
    }

    return [
      ...prev,
      ...getAllHeadingDescendantsByEntityType(
        entities,
        childId,
        entityType,
        isMobile,
      ),
    ]
  }, [] as Entities)
}

export function hasAllVisibleAscendants(
  targetEntity: EntityUnionType,
  entities: EntitiesStructure,
  isDesktop: boolean,
) {
  const ascendants = getEntityAncestors(entities, targetEntity)
  return Object.values(ascendants).every(entity => isVisible(entity, isDesktop))
}

export function getDescendantIds(
  entities: EntitiesStructure,
  entityId: string,
): string[] {
  const entity = entities[entityId]
  if (!entity || !entity.childIds) {
    return []
  }

  return entity.childIds.reduce((prev, childId) => {
    return [...prev, childId, ...getDescendantIds(entities, childId)]
  }, [] as string[])
}

export function getFilteredEntitiesIdsByPageType(
  entities: Record<string, OldEntityInterface | EntityInterface>,
  type: string,
) {
  const pageEntityTypes = getPageTypeEntities(type)
  let excludedEntitiesIds: string[] = []

  Object.values(entities).forEach(entity => {
    if (!pageEntityTypes.includes(entity.type)) {
      excludedEntitiesIds.push(entity.id)
      const descendantIds = getAllDescendantIds(entities, entity.id)
      excludedEntitiesIds = excludedEntitiesIds.concat(descendantIds)
    }
  })

  return excludedEntitiesIds
}

export function getVisibleOptInRecaptchas(
  state: PageState,
  isDesktop: boolean,
  popupId?: string | null,
) {
  return Object.values(state.entities)
    .filter(entity => entity.type === EntityTypeEnum.OptInRecaptcha)
    .filter(
      entity =>
        isVisible(entity, isDesktop) &&
        hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
    .filter(entity =>
      popupId
        ? isPopupAscendantOfEntity(
            state.entities[popupId],
            entity,
            state.entities,
          )
        : isNotBelongsToPopups(entity, state.entities),
    )
}

export function getEntitiesByTypes(
  entities: EntitiesStructure,
  types: Array<EntityTypeEnum | string>,
) {
  return Object.values(entities).filter(entity => types.includes(entity.type))
}

export function checkIsEntityMigrated(
  entities: EntitiesStructure,
  types: Array<EntityTypeEnum | string>,
  targetType: EntityTypeEnum,
) {
  const matchedEntity = Object.values(entities).find(entity =>
    types.includes(entity.type),
  )
  return matchedEntity?.type === targetType
}
