import styled, { css } from 'styled-components'
import {
  backgroundColorCss,
  mobileBackgroundColorCss,
} from 'common/utils/styleUtilsNew'

const beforeAfter = css`
  content: '';
  width: 2px;
  height: 30px;

  position: absolute;
  top: 0;
  left: 13px;
  right: 0;
  bottom: 0;

  background-color: #333;
  box-shadow: 0px 0px 1px #fff;
`

interface CloseRemotePopupBodyButtonUiProps {
  backgroundColor?: string
  mobileBackgroundColor?: string
}

// @ts-expect-error: can't fix it quickly
export const CloseRemotePopupBodyButtonUi = styled.button.attrs(() => ({
  'data-testid': 'popup-close-icon',
}))<CloseRemotePopupBodyButtonUiProps>`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  z-index: 1;
  background: none;
  outline: none;
  border: none;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
  transition: opacity 250ms ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  &:before {
    ${beforeAfter};
    ${backgroundColorCss};
    transform: rotate(45deg);
    ${p => p.theme.phone} {
      ${mobileBackgroundColorCss};
    }
    ${p =>
      p.theme.isEditorMobileMode &&
      css`
        ${mobileBackgroundColorCss}
      `}
  }
  &:after {
    ${beforeAfter};
    ${backgroundColorCss};
    transform: rotate(-45deg);
    ${p => p.theme.phone} {
      ${mobileBackgroundColorCss};
    }
    ${p =>
      p.theme.isEditorMobileMode &&
      css`
        ${mobileBackgroundColorCss}
      `}
  }
`
