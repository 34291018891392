import { pageTypeNode, pageTypes } from 'common/constants/pageTypes'
import structureTypes from '../../common/constants/structureTypes'
import EntityTypeEnum from '../../common/enums/entityTypeEnum'

export const findEntityByType = (entities, type) =>
  Object.values(entities).find(entity => entity.type === type)

export const getEntitiesByType = (entities, type) =>
  Object.values(entities)
    .filter(entity => entity.type === type)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    )

export const getArrayOfEntitiesByTypes = (entities, types) =>
  Object.values(entities).filter(entity => types.includes(entity.type))

export function hasEntityType(entities, type) {
  return Object.values(entities).some(entity => entity.type === type)
}

export const getRootEntity = (entities, types) =>
  Object.values(entities).find(entity => types.includes(entity.type))

export const getAllDescendants = (entities, nodeId) => {
  if (!entities[nodeId] || !entities[nodeId].childIds) {
    return []
  }

  return entities[nodeId].childIds
    .filter(childId => !!entities[childId])
    .reduce(
      (prev, childId) => [
        ...prev,
        entities[childId],
        ...getAllDescendants(entities, childId),
      ],
      [],
    )
}

export const getAllDescendantIds = (entities, nodeId) => {
  if (!entities[nodeId] || !entities[nodeId].childIds) {
    return []
  }
  return entities[nodeId].childIds.reduce(
    (prev, childId) => [
      ...prev,
      childId,
      ...getAllDescendantIds(entities, childId),
    ],
    [],
  )
}

export const findDescendantsByType = (entities, ascendantId, type) =>
  getAllDescendants(entities, ascendantId).filter(
    entity => entity.type === type,
  )

export function getEntityAncestors(entities, targetEntity) {
  return Object.values(entities)
    .filter(entity => entity.id === targetEntity.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
        ...(cur.parentId && entities[cur.parentId]
          ? getEntityAncestors(entities, entities[cur.parentId])
          : null),
      }),
      {},
    )
}

export function isVisible(entity, isDesktop) {
  if (
    entity.type in EntityTypeEnum ||
    entity.type === EntityTypeEnum.Carousel
  ) {
    if (!entity.appearance) {
      return true
    }

    if (isDesktop) {
      // desktop visibility
      return entity.appearance.desktop === isDesktop
    } else {
      // mobile visibility
      return entity.appearance.mobile === true
    }
  }

  if (!entity.options.appearance) {
    return true
  }

  if (isDesktop) {
    // desktop visibility
    return entity.options.appearance.desktop === isDesktop
  } else {
    // mobile visibility
    return entity.options.appearance.mobile === true
  }
}

export function isPopupAscendantOfEntity(popup, targetEntity, entities) {
  return getAllDescendantIds(entities, popup.id).includes(targetEntity.id)
}

export function isNotBelongsToPopups(targetEntity, entities) {
  const popups = getEntitiesByType(entities, structureTypes.POPUP)
  return Object.values(popups).every(
    popup =>
      getAllDescendantIds(entities, popup.id).includes(targetEntity.id) ===
      false,
  )
}

export function hasAllVisibleAscendants(targetEntity, entities, isDesktop) {
  const ascendants = getEntityAncestors(entities, targetEntity)
  return Object.values(ascendants).every(entity => isVisible(entity, isDesktop))
}

export function findLostEntitiesIds(entities, pageType) {
  let rootEntity = getRootEntity(entities, pageTypeNode[pageType])
  if (
    !rootEntity &&
    (pageType === pageTypes.blogStatic || pageType === pageTypes.blogHome)
  ) {
    rootEntity = getRootEntity(entities, [structureTypes.BLOG_PAGE_BODY, EntityTypeEnum.BlogPageBody])
  }

  if (!rootEntity && pageType === pageTypes.blogPostBody) {
    rootEntity = getRootEntity(entities, [structureTypes.BLOG_POST_BODY])
  }

  const popups = getEntitiesByType(entities, structureTypes.POPUP)

  const popupsEntitiesIds = Object.keys(popups)
    .map(popupId => getAllDescendantIds(entities, popupId))
    .flat()

  const entitiesIdsWithoutRootIdAndPopups = Object.values(entities)
    .filter(entity => entity.type !== structureTypes.POPUP)
    .filter(entity => entity.id !== rootEntity.id)
    .map(entity => entity.id)

  const bodyDescendantIds = getAllDescendantIds(entities, rootEntity.id)

  const filteredEntities = popupsEntitiesIds.concat(bodyDescendantIds)

  return entitiesIdsWithoutRootIdAndPopups.filter(
    id => filteredEntities.includes(id) === false,
  )
}

// we omit rendering because their need to merge layout
export const restrictedToRenderPageTypes = [
  pageTypes.blogPostBody,
  pageTypes.blogStatic,
  pageTypes.blogHome,
]

export function getEntitiesFileIds(entities) {
  const fileIds = []
  Object.values(entities).forEach(entity => {
    if ('fileId' in entity) {
      fileIds.push(entity.fileId)
    }

    if ('backgroundFileId' in entity) {
      fileIds.push(entity.backgroundFileId)
    }

    if ('headerFontFileId' in entity) {
      fileIds.push(entity.headerFontFileId)
    }

    if ('contentFontFileId' in entity) {
      fileIds.push(entity.contentFontFileId)
    }

    if ('fontFileId' in entity) {
      fileIds.push(entity.fontFileId)
    }

    if ('mobileFontFileId' in entity) {
      fileIds.push(entity.mobileFontFileId)
    }

    if ('mobileFontFileId' in entity) {
      fileIds.push(entity.mobileFontFileId)
    }

    if ('textFontFileId' in entity) {
      fileIds.push(entity.textFontFileId)
    }

    if ('mobileTextFontFileId' in entity) {
      fileIds.push(entity.mobileTextFontFileId)
    }

    if ('subTextFontFileId' in entity) {
      fileIds.push(entity.subTextFontFileId)
    }

    if ('mobileSubTextFontFileId' in entity) {
      fileIds.push(entity.mobileSubTextFontFileId)
    }

    if ('titleFontFileId' in entity) {
      fileIds.push(entity.titleFontFileId)
    }

    if ('mobileTitleFontFileId' in entity) {
      fileIds.push(entity.mobileTitleFontFileId)
    }

    if ('descriptionFontFileId' in entity) {
      fileIds.push(entity.descriptionFontFileId)
    }

    if ('mobileDescriptionFontFileId' in entity) {
      fileIds.push(entity.mobileDescriptionFontFileId)
    }

    if ('categoriesFontFileId' in entity) {
      fileIds.push(entity.categoriesFontFileId)
    }

    if ('mobileCategoriesFontFileId' in entity) {
      fileIds.push(entity.mobileCategoriesFontFileId)
    }

    if ('dateFontFileId' in entity) {
      fileIds.push(entity.dateFontFileId)
    }

    if ('mobileDateFontFileId' in entity) {
      fileIds.push(entity.mobileDateFontFileId)
    }

    if ('posterFileId' in entity) {
      fileIds.push(entity.posterFileId)
    }

    if ('posterFileId' in entity) {
      fileIds.push(entity.posterFileId)
    }

    if ('options' in entity) {
      if ('backgroundFileId' in entity.options) {
        fileIds.push(entity.options.backgroundFileId)
      }

      if ('srcFileId' in entity.options) {
        fileIds.push(entity.options.srcFileId)
      }

      if ('videoFileId' in entity.options) {
        fileIds.push(entity.options.videoFileId)
      }

      if ('textFontFileId' in entity.options) {
        fileIds.push(entity.options.textFontFileId)
      }

      if ('subTextFontFileId' in entity.options) {
        fileIds.push(entity.options.subTextFontFileId)
      }

      if ('fontFileId' in entity.options) {
        fileIds.push(entity.options.fontFileId)
      }
    }

    if ('mobileOptions' in entity) {
      if ('backgroundFileId' in entity.mobileOptions) {
        fileIds.push(entity.mobileOptions.backgroundFileId)
      }

      if ('srcFileId' in entity.mobileOptions) {
        fileIds.push(entity.mobileOptions.srcFileId)
      }

      if ('videoFileId' in entity.mobileOptions) {
        fileIds.push(entity.mobileOptions.videoFileId)
      }

      if ('textFontFileId' in entity.mobileOptions) {
        fileIds.push(entity.mobileOptions.textFontFileId)
      }

      if ('subTextFontFileId' in entity.mobileOptions) {
        fileIds.push(entity.mobileOptions.subTextFontFileId)
      }

      if ('fontFileId' in entity.mobileOptions) {
        fileIds.push(entity.mobileOptions.fontFileId)
      }
    }
  })

  return fileIds
}
